var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/monitoring";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"npHSoF7Gppf32WRsNoruv"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { CaptureConsole } from "@sentry/integrations"
import * as Sentry from "@sentry/nextjs"

Sentry.init({
  // Tells Sentry which project to send events to.
  dsn: "https://752e9035ba7a6f9a02ac4fbbfc4aef56@o306153.ingest.sentry.io/4505959385202688",

  // Sets the environment tag to the Sentry alert that is sent to Sentry.io
  environment:
    location &&
    (location.hostname.includes("prd.span.io") ||
      location.hostname.includes("app.span.io"))
      ? "production"
      : location && location.hostname.includes("int.span.tools")
      ? "integration"
      : "development",

  // Adjust this value in production, or use tracesSampler for greater control
  // NOTE: This is set to 0.0 because SPAN is not currently using the performance monitoring features of Sentry.
  tracesSampleRate: 0.0,

  enableTracing: false,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    new Sentry.Replay({
      // Additional Replay configuration goes in here
      maskAllText: false,
      blockAllMedia: true,
    }),
    new CaptureConsole({
      levels: ["error"],
    }),
  ],
})
